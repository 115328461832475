<template>
  <b-col
    lg="4"
    sm="12"
  >
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            cols="12"
            class="cursor-pointer"
            @click="searchDeclaration()"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <div class="card-metric">
                  <h1
                    v-if="!loading"
                    :class="className"
                  >{{ amount }}</h1>
                  <b-spinner
                    v-if="loading"
                    variant="secondary"
                    label="Loading"
                  />
                </div>
                <h5>{{ title }}</h5>
              </div>
              <h3 class="m-0">
                <feather-icon
                  size="48"
                  :icon="icon"
                  :class="className"
                  class="mr-1"
                />
              </h3>
            </div>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </b-col>
</template>

<script>
import { BCard, BRow, BCol, BCardText, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BSpinner,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    filters: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    searchDeclaration() {
      this.$router.push({ name: 'parcels', params: { status: this.title, filters: this.filters } })
    },
  },
}
</script>

<style>
  .unfinished-declaration-cards .card-metric span {
    margin-bottom: 10px;
  }
</style>
